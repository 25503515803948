.rider-workshift-total .total{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.rider-workshift-total .note{
    font-size: var(--font-xs);
    color: var(--ui-placeholder);
    font-weight: normal;
}

.rider-workshift-total .total .amount{
    font-size: var(--font-xxl);
    font-weight: bold;
}

.rider-workshift-total .total .button{
    margin-top: 10px;
}
