.image-cropper{
    position: relative;
}

.image-cropper .thumbnail{
    width: 100px;
    height: 100px;
}

.crop-container{
    position: relative;
    height: 30vh;
}

.crop-container .reactEasyCrop_CropArea{
    color: var(--ui-overlay);
}

.crop-modal .controls > div{
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: var(--ui-element);
    border-radius: 5px;
    margin: 10px 0;
}

.image-uploader{
    display: flex;
    align-items: center;
    background: var(--ui-element);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .image-uploader .preview{
    box-sizing: border-box;
    overflow: hidden;
    justify-content: center;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .image-uploader .preview .delete{
    display: none;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    justify-content: center;
    color: var(--main-color);
    font-size: var(--font-m);
    cursor: pointer;
  }
  
  .image-uploader .preview:hover .delete{
    display: flex;
  }
  
  .image-uploader .file-uploader{
    margin-left: auto;
    flex: 1
  }
  
  .image-uploader .file-uploader .drop-box{
    width: 100%;
  }
  
  .image-uploader .file-uploader .drop-box > span{
    white-space: nowrap;
  }
  
  .image-uploader .preview img{
    box-sizing: border-box;
    height: auto;
    width: 100%;
    background: transparent;
  }
  
  @media only screen and (max-width: 480px) {
  
    .image-uploader .preview{
      height: 60px;
    }
  
    .image-uploader .file-uploader .drop-box{
      padding: 30px;
    }
  
  }
  