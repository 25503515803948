.sliding-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sliding-menu::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.sliding-menu {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.sliding-menu > *{
    padding: 15px 30px;
    background-color: var(--ui-element);
    border-radius: 5px;
}

.sliding-menu > *.active{
    background-color: var(--main-color);
    color: var(--main-title);
}