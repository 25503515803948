.filter-search{
  position: relative;
}

.filter-search .labeled-input{
  height: 100%;
}

.filter-search .actions{
  position: absolute;
  display: none;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--main-color)
}

.filter-search.populated .actions{
  display: flex;
}
