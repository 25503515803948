.rider-balance{
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--ui-border);
  padding-bottom: 15px;
}

.rider-balance .balances{
  display: flex;
  justify-content: center;
}

.rider-balance .balance{
  display: flex;
  align-items: center;
  gap: 15px;
  background: var(--ui-element);
  padding: 10px;
  border-radius: 5px;
}

.rider-balance .balance .icon{
  width: 60px;
  font-size: 40px;
  text-align: center;
}

.rider-balance .balance .value{
  font-size: 30px
}

.rider-balance .balance > div{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rider-balance .placeholder{
  color: var(--ui-placeholder);
}

.rider-balance .value{
  text-align: right;
}