#dispatcher-bar{
  background: var(--ui-bg);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  gap: 15px
}

#dispatcher-bar .bar-actions{
  padding: 0 10px;
  display: flex;
  align-items: stretch;
  gap: 10px;
  flex: 1;
  overflow: scroll;
}

#dispatcher-bar .dispatcher-actions .button{
  box-shadow: none;
}

#dispatcher-bar .bar-actions::-webkit-scrollbar {
  display: none;
  width: 0px;
}

#dispatcher-bar .btn{
  position: relative;
  padding: 8px 12px;
  font-size: var(--font-s);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ui-element);
  border: 1px solid transparent;
  color: var(--ui-text);
  cursor: pointer;
  margin: 0;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  transition: all .2s ease-in-out;
  box-shadow: none;
  white-space: nowrap;
}

#dispatcher-actions-modal .modal-content{
  display: flex;
  flex-direction: column;
  gap: 15px
}

#dispatcher-bar > div .fa{
  margin-right: 5px;
  color: var(--ui-placeholder);
}

#dispatcher-bar > div .label{
  text-transform: uppercase;
  color: var(--ui-placeholder);
  margin-right: 10px;
}

#dispatcher-bar .left{
  margin-left: auto;
}

#dispatcher-bar .valid *{
  color: var(--valid-color) !important
}

#dispatcher-bar .invalid *{
  color: var(--invalid-color) !important
}

#dispatcher-bar .dispatcher-infos{
  margin-left: auto;
  display: flex;
  gap: 15px;
  padding-right: 15px;
  display: flex;
  flex-wrap: nowrap;
}

#dispatcher-bar .dispatcher-infos > div{
  display: flex;
  gap: 5px
}


@media only screen and (max-width: 600px) {

  #dispatcher-bar{
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 15px;
  }

  #dispatcher-bar > div{
    flex: 1;
  }
  
  #dispatcher-bar .dispatcher-infos{
    margin-left: 0;
    flex-direction: row;
    align-items: stretch;
    padding-right: 0;
    gap: 10px;
    display: flex;
    flex-wrap: nowrap;
  }

}

#available-riders-modal .modal-content{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.rider-buttons{
  display: flex;
  flex-direction: column;
  gap: 10px
}

.rider-buttons .rider-button{
  padding: 8px 12px;
  font-size: var(--font-s);
  display: flex;
  gap: 10px;
  align-items: center;
  background: var(--ui-element);
  border: 0;
  color: var(--ui-text);
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  transition: all .2s ease-in-out;
}

.rider-buttons .rider-button button{
  margin-left: auto;
}

.rider-buttons .rider-button .label{
  flex: 1;
  gap: 5px;
  padding: 10px 0;
}

.rider-buttons .rider-button:hover{
  background: var(--ui-element-hvr);
}

.rider-buttons .rider-button > div{
  display: flex
}

.rider-buttons .rider-button .actions-dropdown{
  margin-left: 5px;
}

#available-riders-modal .onbreak{
  font-size: var(--font-xs);
  color: var(--invalid-color)
}

#available-riders-modal .instrasfer{
  font-size: var(--font-xs);
  color: var(--warning-color)
}

#available-riders-modal .requested-icon{
  font-size: var(--font-s);
  color: var(--ui-placeholder);
  margin-right: 5px;
}

#available-riders-modal .requested-icon.finished{
  color: var(--valid-color)
}

#available-riders-modal .requested-icon.onbreak{
  color: var(--invalid-color)
}

#available-riders-modal .requested-icon.requested{
  color: var(--warning-color)
}
