#purchase-customer .customer-name{
  margin: 0 0 20px;
}

#purchase-customer .customer-name .link{
  font-size: var(--font-l);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;  
}

#purchase-customer .customer-name .email{
  margin-top: 5px;
  color: var(--ui-placeholder);
  font-size: var(--font-xs);
}

#purchase-customer .customer-contact{
  display: flex;
  flex-direction: column;
}

#purchase-customer .customer-contact .button{
  margin: 10px 0;
  padding: 15px;
}

#purchase-customer .customer-contact .button i{
  margin-right: 10px;

  font-size: var(--font-m);
  font-weight: bold
}

#purchase-customer .customer-contact #whatsapp.button{
  background: #25D366;
  color: #ffffff;
}

#purchase-customer .customer-contact #imessage.button{
  background: #147efb;
  color: #ffffff;
}

#purchase-customer .customer-contact #imessage.button i,
#purchase-customer .customer-contact #whatsapp.button i{
  color: #ffffff;
}

#purchase-customer .customer-contact .phone-number{
  display: flex;
  margin-bottom: 10px;
}

#purchase-customer .customer-contact .phone-number{
  font-size: var(--font-m);
}

#purchase-customer .customer-contact .phone-number .phone-picker{
  flex: 1;
}

#purchase-customer .customer-contact .phone-number .phone-picker .labeled-input label input{
  font-size: var(--font-m);

}
