.stat-bar{
    display: flex;
    background-color: var(--ui-bg);
    padding: 15px;
    gap: 15px;
    margin-bottom: 15px;
}

.stat-bar > div{
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--ui-placeholder);
    gap: 5px;
    font-size: var(--font-xs);
}

.stat-bar > div .value{
    font-size: var(--font-l);
}