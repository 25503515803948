.workshift-details{
    display: flex;
    align-items: center;
    margin-top: 20px;
    background-color: var(--ui-element);
    border-radius: 5px;
    width: 100%;
    overflow-x: auto;
}

.workshift-details::-webkit-scrollbar{
    display: none;
}

.workshift-details .detail{
    display: flex;
    text-align: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 15px;
    font-size: var(--font-m);
    font-weight: bold;
}

.workshift-details .detail.plus{
    color: var(--valid-color);
}

.workshift-details .detail.minus{
    color: var(--invalid-color);
}

.workshift-details .detail .note{
    font-size: var(--font-xxs);
    color: var(--ui-placeholder);
    font-weight: normal;
}