#confirm-portal{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(30, 30, 30,.6);
}

#confirm-portal .confirm-modal{
  background: var(--main-bg-helper);
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,.4);
  border: 0;
  border-radius: 5px;
  padding: 20px;
}

#confirm-portal .confirm-modal .title{
  padding: 0 0 20px;
  border-bottom: 1px solid var(--ui-border);
  font-size: var(--font-m)
}

#confirm-portal .confirm-modal .desc{
  padding: 20px 0;
}

#confirm-portal .confirm-modal .choices{
  display: flex;
  gap: 10px;
}

#confirm-portal .confirm-modal .choices > *{
  flex: 1;
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--main-title)
}

#confirm-portal .confirm-modal .choices .accept{
  background: var(--valid-color)
}

#confirm-portal .confirm-modal .choices .reject{
  background: var(--invalid-color)
}
