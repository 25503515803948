#invoice-form{
  position: relative;
}

#invoice-form .invoice-form{
  padding: 20px;
  display: flex;
  flex-direction: column;
}

#invoice-form .invoice-form th{
  text-align: center;
  font-weight: bold
}

#invoice-form .invoice-form td{
  text-align: center;
}

#invoice-form .form{
  display: flex;
  flex-direction: column;
}

#invoice-form .form > *{
  width: 100%;
  margin-bottom: 10px;
}

#invoice-form .form > *:last-child{
  margin-bottom: 0;
}

#invoice-form .form .labeled-textarea label textarea{
  width: 100%;
}
