.multi-tag{
  display: flex;
  background: var(--ui-element);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
}

.multi-tag .options,
.multi-tag .values{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.multi-tag .options{
  border-right: 0;
}

.multi-tag .title{
  margin-bottom: 20px;
}

.multi-tag .items-list{
  flex: 1;
  position: relative;
  max-height: 320px;
  overflow-y: scroll;
}

.multi-tag .items-list:first-child{
  border-right: 0;
}

.multi-tag .items-list .empty{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  bottom: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.multi-tag .item{
  background: var(--ui-element-hvr);
  border: 1px solid var(--ui-border);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.multi-tag .item .icon{
  margin-right: 10px;
}

.multi-tag .item:last-child{
  margin-bottom: 0;
}

.multi-tag .item .fa{
  margin-left: auto;
  font-size: var(--font-xs);
  cursor: pointer;
}

.multi-tag .item .fa-plus-circle{
  color: var(--valid-color)
}

.multi-tag .item .fa-minus-circle{
  color: var(--invalid-color)
}

@media only screen and (max-width: 480px) {

  .multi-tag{
    flex-direction: column;
  }

  .multi-tag .options{
    margin-bottom: 20px;
  }

  .multi-tag .items-list .empty{
    position: relative;
  }

}
