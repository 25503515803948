.title h1,
.title h2,
.title h3,
.title h4{
  position: relative;
  font-weight: bold;  
}

.title h1{
  font-size: var(--font-xxl);
}

.title h2{
  font-size: var(--font-xl);
  color: var(--main-text)
}

.title h4{
  font-size: var(--font-m)  
}

.title.styled h3{
  font-size: var(--font-m);
  font-weight: bold;
  margin: 2rem 0 1rem;
  line-height: 1.4;    
}

@media only screen and (max-width: 600px) {
  .title.styled h3{
      text-align: center;
  }
}