#tasks .task.requires_confirmation .status .fa,
#tasks .task.inqueue .status .fa{
  color: white
}

#tasks .task.processed .status .fa{
  color: grey
}

#tasks .task.inpreparation .status .fa{
  color: #ffdc41
}

#tasks .task.readyforcollection .status .fa{
  color: #ff8b12
}

#tasks .task.ontheway .status .fa{
  color: #1984ff
}

#tasks .task.atlocation .status .fa{
  color: #32d13b
}

#tasks .task.completed .status .fa{
  color: #3c5922
}

#tasks .task .task-field .tooltip{
  justify-content: center;
}

#tasks .task .task-field .help{
  font-size: var(--font-xs);
  color: var(--main-color);
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

#tasks .task .task-field .help .info{
  color: var(--ui-placeholder);
}

#tasks .task-actions .context-menu{
  width: 100%;
}
