.labeled-textarea{
  position: relative;
  margin: 0;
  z-index: 1;
}

.labeled-textarea label{
  position: relative;
  display: block;
  margin: 0;
}

.labeled-textarea label textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  resize: none;
  margin: 0;
  border: none;
  padding: 15px;
  background: var(--ui-element);
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
  color: var(--ui-text);
  border-radius: 5px;
  border: 0;
  transition: all 0.2s ease-in-out;
}

.labeled-textarea label textarea::-webkit-input-placeholder {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: var(--ui-placeholder);
  font-size: 14px;
}

.labeled-textarea label textarea::-moz-placeholder {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: var(--ui-placeholder);
  font-size: 14px;
}

.labeled-textarea label textarea:-ms-input-placeholder {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: var(--ui-placeholder);
  font-size: 14px;
}

.labeled-textarea label textarea::placeholder {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: var(--ui-placeholder);
  font-size: 14px;
}

.labeled-textarea label textarea:focus,
.labeled-textarea.populated label textarea {
  padding-top: 28px;
  padding-bottom: 12px;
}

.labeled-textarea label textarea:focus::-webkit-input-placeholder,
.labeled-textarea.populated label textarea::-webkit-input-placeholder {
  color: transparent;
}

.labeled-textarea label textarea:focus::-moz-placeholder,
.labeled-textarea.populated label textarea::-moz-placeholder {
  color: transparent;
}

.labeled-textarea label textarea:focus:-ms-input-placeholder,
.labeled-textarea.populated label textarea:-ms-input-placeholder {
  color: transparent;
}

.labeled-textarea label textarea:focus::placeholder,
.labeled-textarea.populated label textarea::placeholder {
  color: transparent;
}

.labeled-textarea label textarea:focus + span,
.labeled-textarea.populated label textarea + span {
  opacity: 1;
  top: 10px;
  left: 15px;
}

.labeled-textarea label textarea + span {
  color: var(--main-text);
  font-weight: bold;
  font-size: var(--font-xxs);
  position: absolute;
  top: 0px;
  left: 20px;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
