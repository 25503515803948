.details-bar{
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

.details-bar .detail{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.details-bar .detail > .inner{
    display: flex;
    align-items: center;
    gap: 5px
}

.details-bar .detail .inner .icon > .fa{
    color: var(--main-color)
}

.details-bar .detail .label{
    font-size: var(--font-xs);
    color: var(--ui-placeholder)
}

.details-bar .detail.left{
    margin-left: auto;
}

@media only screen and (max-width: 800px) {

    .details-bar{
        justify-content: center;
    }

    .details-bar .detail.left{
        margin-left: 0;
    }

}