#vendor-promotions .promotions{
  display: flex;
  flex-direction: column;
  width: 100%;
}

#vendor-promotions .promotions .promotion{
  display: flex;
  border: 1px solid var(--ui-border);
  padding: 20px;
  align-items: center;
  margin-bottom: 10px;
}

#vendor-promotions .promotions .promotion .info{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#vendor-promotions .promotions .promotion .info .desc{
  color: var(--ui-placeholder);
  margin-top: 5px;
}

#vendor-promotions .promotions .promotion .actions{
  margin-left: auto;
  display: flex;
  align-items: center;
}

#vendor-promotions .promotions .promotion .actions .button{
  font-size: var(--font-xs);
  text-align: center;
  margin-right: 5px;
  width: 40px;
  height: 40px;
}

#vendor-promotions .promotions .promotion .actions .button .fa{
  margin-right: 0
}
