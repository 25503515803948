.logo{
  display: flex;
  align-items: center;
}

.logo *{
  cursor: pointer;
}

.logo img{
  height: 40px;
  width: auto;
}

.logo span{
  text-transform: uppercase;
  color: var(--ui-color);
  font-size: 24px;
}

@media only screen and (max-width: 414px) {

  .logo img{
    height: 30px;
  }

}
