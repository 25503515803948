.segmented-control {
    display: inline-flex;
    position: relative;
    align-items: stretch;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
    z-index: 1;
    background: var(--ui-element);
    border-radius: 5px;
    overflow: hidden;
    color: var(--ui-text);
  }

  .segmented-control > div{
      padding: 10px 20px;
      display: flex;
      align-items: center;
  }

  .segmented-control > div.selected{
    background: var(--main-color);
    color: var(--main-bg)
  }

  .segmented-control label {
    flex: 1;
    cursor: pointer;
  }
