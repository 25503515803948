#group-box{
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 5px;
  background: var(--main-element);
  padding: 15px;
  display: flex;
  align-items: center;
  z-index: 900;
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,.4);
}

#group-box h4{
  color: var(--ui-placeholder);
  margin-bottom: 10px;
}

#group-box .items{
  margin-right: 20px
}

#group-box .group-item td{
  padding: 10px;
  white-space: nowrap;
  vertical-align: middle;
}

#group-box .actions{
  margin-top: 10px;
}

.search-placeholder{
  padding: 40px;
  text-align: center;
  font-size: var(--font-l);  
}
