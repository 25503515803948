.rdw-editor-wrapper{
    background-color: var(--ui-element);
    border-radius: 5px;
    box-shadow: 0px 2px 2px 0px var(--ui-shadow);
}
  
.rdw-editor-toolbar{
    background: none !important;
    border: none;
    border-bottom: 1px solid var(--ui-border);
    padding-bottom: 5px;
}

.rdw-option-wrapper{
    cursor: pointer;
    background: var(--ui-element-hvr);
    border: 3px solid var(--ui-border);
    box-sizing: border-box;
    padding: 5px;
    min-width: 40px;
    height: 40px;
    color: var(--ui-text);
    box-shadow: none;
}

.rdw-option-wrapper:hover{
    box-shadow: none;
    border: 3px solid var(--ui-border);
    background: var(--ui-element);
}
  
.rdw-editor-main{
    padding: 0 15px;
}

.rdw-dropdown-wrapper{
    cursor: pointer;
    background: var(--ui-element-hvr);
    min-width: 40px;
    height: 40px;
    border: 3px solid var(--ui-border);
    box-sizing: border-box;
}

.rdw-dropdown-wrapper:hover{
    box-shadow: none;
    border: 3px solid var(--ui-border);
    background: var(--ui-element);    
    box-shadow: none;
}

.rdw-dropdown-optionwrapper:hover,
.rdw-dropdown-optionwrapper{
    box-shadow: none;
    background: var(--ui-element-hvr);
    box-sizing: border-box;
    border: 1px solid var(--ui-border);
}

.rdw-dropdownoption-active,
.rdw-dropdownoption-highlighted{
    background: var(--ui-element);
}