.vendor-balance{
  display: flex;
  justify-content: space-around;
}

.vendor-balance .balances{
  display: flex;
  justify-content: space-around;
}

.vendor-balance .balance{
  display: flex;
  align-items: center;

}

.vendor-balance .balance .icon{
  width: 60px;
  font-size: 50px;
  text-align: center;
}

.vendor-balance .balance .value{
  font-size: 30px
}
