#customer-payment-methods{
    display: flex;
    flex-direction: column;
    gap: 15px
}

#customer-payment-methods .payment-method{
  display: flex;
  border-radius: 5px;;
  padding: 15px;
  gap: 15px;
  background: var(--ui-bg-helper);
}

#customer-payment-methods .payment-method svg{
  height: 50px;
  width: auto;
}

#customer-payment-methods .payment-method .info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1
}

#customer-payment-methods .payment-method .actions{
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

#customer-payment-methods .payment-method .actions .button{
  width: 40px;
  height: 40px;
}

#customer-payment-methods .payment-method .building{
  color: var(--ui-placeholder);
  margin-top: 5px;
}
