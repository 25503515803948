.confirm-buttons{
  display: flex;
}

.confirm-buttons .button{
  height: 40px;
  box-sizing: border-box;
}

.confirm-buttons .button:first-child{
  margin-right: 5px;
}

.confirm-buttons .button.approve{
  background: var(--valid-color);
  color: white
}

.confirm-buttons .button.reject{
  background: var(--invalid-color);
  color: white
}

.confirm-buttons .button .fa{
  margin-right: 0;
}
