#product-addons{
  position: relative;
}

#product-addons > .actions{
  margin-bottom: 20px;
}

#product-addons .product-addon{
  margin-bottom: 20px;
  background: var(--ui-element);
  box-shadow: 0px 2px 2px 0px rgba(200,200,200,.4);
  padding: 15px;
  border-radius: 5px;
}

#product-addons .product-addon .product-addon-label{
  border-bottom: 1px solid var(--ui-border);
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
}

#product-addons .product-addon .product-addon-label .quantity-limits{
  margin-left: 20px;
  color: var(--ui-placeholder)
}

#product-addons .product-addon .product-addon-label .actions{
  margin-left: auto;
  font-size: var(--font-xs);
  cursor: pointer;
}

#product-addons .product-addon-options .product-addon-option{
  display: flex;
  align-items: center;
  color: var(--main-placeholder);
  margin-bottom: 5px;
}

#product-addons .product-addon-options .product-addon-option .price{
  margin-left: auto;
  color: var(--main-color)
}

#product-addons .actions{
  justify-content: center;
}

#product-addons .actions .add-option{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-xs);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

#product-addons .actions .add-option .fa{
  font-size: var(--font-xxs);
  margin-right: 5px;
}

#product-addons .actions .add-option:hover{
  color: var(--main-color)
}
