#dispatcher .block{
  border-radius: 5px;
}

#dispatcher .block-title{
  text-align: center;
  padding: 20px 0;
}

#dispatcher .block-title h3{
  font-size: var(--font-l);
}

#dispatcher .block-title .info{
  color: var(--ui-placeholder);
  margin-top: 5px;
  display: block;
}