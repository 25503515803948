#purchase-product .product-actions{
    margin-top: 15px;
    display: flex;
    gap: 15px
}

#purchase-product .product-actions .buttons{
    margin-left: auto;
    display: flex;
    gap: 15px
}

@media only screen and (max-width: 480px) {
    
    #purchase-product .product-actions{
        overflow-x: scroll;    
    }

    #purchase-product .product-actions .buttons{
        flex-direction: row-reverse;        
    }

    #purchase-product .product-actions {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  
    }
    
    #purchase-product .product-actions::-webkit-scrollbar {
        display: none;
    }      

    #purchase-product .product-actions .button{
        margin-left: 0;
    }

}