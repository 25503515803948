#order-feedback{
  display: flex;
  justify-content: center;
  align-items: center;
}

#order-feedback > div{
  display: flex;
  justify-content: center;
}

#order-feedback .product-feedback{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  flex: 1
}

#order-feedback .product-feedback .title{
  margin-bottom: 20px;
}

#order-feedback .product-feedback:first-child{
  margin-left: 0;
}

#order-feedback .product-feedback:last-child{
  margin-right: 0;
}

#order-feedback .product-feedback .faces{
  display: flex;
  margin-bottom: 20px;
  font-size: var(--font-xxl)
}

#order-feedback .product-feedback .faces .face{
  margin: 0 10px;
  opacity: .7;
  color: var(--ui-placeholder)
}

#order-feedback .product-feedback .faces .face.active{
  opacity: 1;
}

#order-feedback .product-feedback .faces .face:first-child{
  margin-left: 0;
}

#order-feedback .product-feedback .faces .face:last-child{
  margin-right: 0;
}

#order-feedback .product-feedback .active .fa-frown{
  color: var(--invalid-color)
}

#order-feedback .product-feedback .active .fa-meh{
  color: var(--warning-color)
}

#order-feedback .product-feedback .active .fa-smile{
  color: var(--valid-color)
}

#order-feedback .void-box{
  margin-top: 20px;
}
