.addon-selector{
  margin: 0 0 10px;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
}

.addon-selector .addon-option{
  display: flex;
  align-items: center;
}

.addon-selector .addon-option.quantifiable .quantity-selector{
  display: flex;
  align-items: center;
  margin: 5px 10px 5px 0;
}

.addon-selector .addon-option.quantifiable .quantity-selector .button{
  padding: 4px 6px;
  font-size: var(--font-xxs);
}

.addon-selector .addon-option.quantifiable .quantity-selector .value{
  padding: 4px 6px;
}

.addon-selector .addon-option .checkbox{
  flex: 1;
  margin: 5px 0;
}

.addon-selector .addon-option .divider{
  margin: 10px;
}

.addon-selector .addon-title{
    display: flex;
}

.addon-selector .addon-title .title{
  font-size: var(--font-m)
}

.addon-selector .addon-title .required{
  margin-left: auto;
  color: var(--ui-placeholder);
  border: 1px solid var(--ui-placeholder);
  border-radius: 5px;
  font-size: 12px;
  padding: 5px;
}

.addon-selector.active .addon-title .required{
  color: var(--invalid-color);
  border: 1px solid var(--invalid-color);
}

.addon-selector .instructions{
  margin-top: 5px;
  font-size: var(--font-s);
  color: var(--ui-placeholder)
}

.addon-selector .warning{
  color: var(--ui-placeholder);
  font-size: var(--font-xxs);
  margin-top: 15px;
}