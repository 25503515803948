.confirm-order-field{
  display: flex;
  justify-content: center;
}

.confirm-order-field .button{
  width: 40px;
  height: 40px;
  box-sizing: border-box;
}

.confirm-order-field .button:first-child{
  margin-right: 5px;
}

.confirm-order-field .button .fa-check{
  color: var(--valid-color);
}

.confirm-order-field .button .fa-times{
  color: var(--invalid-color)
}

.confirm-order-field .button .fa{
  margin-right: 0;
}
