.form{
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.form > *{
  width: 100%;
}

.form .form-field{
  position: relative;
}

.form .form-fieldset{
  display: flex;
  gap: 15px;
  align-items: center;
}

.form .form-fieldset > *{
  flex: 1
}

.form .form-fieldset.top{
  align-items: flex-start;
}

.form .form-fieldset.highlighted{
  flex-direction: column;
  background: var(--ui-bg);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
}

.form .form-fieldset.highlighted .form-field{
  width: 100%;
}

.form .form-field.fluid{
  flex: 1
}


/* DEPRECATED */
.form .field{
  display: flex;
  margin: 15px 0;
}

.form .field > *{
  flex: 1;
  margin-right: 10px;
}

.form .field > *:last-child{
  margin-right: 0;
}

.form .field .warning{
  margin: 10px 5px;
  font-size: var(--font-xs);
  color: var(--ui-placeholder)
}
