.edit-box .phone-picker .country{
  margin-right: 15px;
}

.edit-box.phone-edit-box > .icon{
  padding-right: 0;
}

.edit-box .phone-picker{
  flex: 1
}