.ready-for-collection{
  width: 30px;
  height: 30px;
  font-size: var(--font-xs);
  padding: 0;
  box-sizing: border-box;
  background: var(--valid-color);
  border-color: transparent;
  color: white
}

.ready-for-collection:hover{
  background: white;
  color: var(--valid-color);
  border-color: var(--valid-color)
}

.ready-for-collection .fa{
  margin-right: 0;
}
