.notification{
    cursor: pointer;
}

.notification .notification-title{
    margin: 0 0 10px;
    display: flex;
    background: var(--valid-color);
    border-radius: 5px;
    color: white;
    padding: 15px;
    align-items: center;
  }
  
.notification .notification-title .title{
    font-size: var(--font-m);
    margin-bottom: 10px;
}

.notification .notification-title > .icon i,
.notification .notification-title > .icon .fa{
    font-size: var(--font-xl);
    margin-right: 10px;
}

.notification .notification-title .info{
    margin-left: 5px;
}

.notification .notification-title .info span{
    font-size: var(--font-s)
}

.notification.warning .notification-title{
    background: var(--warning-color);
}

.notification.error .notification-title{
    background: var(--invalid-color);
}

.notification.main .notification-title{
    background: var(--main-color);
}

.notification.ui .notification-title{
    background: var(--ui-element);
    color: var(--ui-placeholder);
}