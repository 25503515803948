.page-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.page-header .url{
    display: flex;
    width: 100%;
    align-items: center;
    color: var(--ui-placeholder);
}

.page-header > div{
    width: 100%;
}

.page-header .inner{
    display: flex;
    flex-direction: column;
}

.page-header .title{
    font-size: var(--font-l);
    position: relative;
}

.page-header .desc{
    font-size: var(--font-s);
    color: var(--ui-placeholder);
    margin: 20px 0 0;
    background-color: var(--ui-bg);
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.page-header .desc .url input{
    background: none;
    border: 0;
    padding: 0;
    flex: 1;
    font-size: var(--font-s);
    color: var(--ui-placeholder);
}

.page-header .actions{
    margin-left: auto;
}