.toggle-list .selected{
  display: flex;
  align-items: center;
}

.toggle-list .selected .button{
  margin-left: auto;
}

.toggle-list .list .list-item{
  margin-bottom: 10px
}
