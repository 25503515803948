.validation{
  position: relative;
}

.validation .errors{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 6000;
}

.validation .error-tag.fa{
  background: var(--invalid-color);
  padding: 6px 8px;
  line-height: 1;
  font-size: 12px;
  border-radius: 5px;
  color: white;
  font-size: var(--font-xs)
}

.validation .tooltip-wrapper .tooltip{
  background: var(--invalid-color);
  border: 1px solid var(--invalid-color);
  color: white;
  left: auto;
  right: 0;
  pointer-events: none;
}

.validation .tooltip-wrapper .tooltip:after{
  border-top-color: var(--invalid-color);
  left: auto;
  right: 3px;
}

.validation .tooltip-wrapper .tooltip{
    opacity: 1;
    animation: hide 0s ease-in 3s forwards;
    animation-fill-mode: forwards;
    transform: translateY(0px);
}

.tooltip-wrapper .error-tag:hover + .tooltip {
  animation: show 0s ease-in 1s forwards;
  animation-fill-mode: forwards;
}


@keyframes hide {
    to {
      opacity: 0
    }
}
@-webkit-keyframes hide {
    to {
      opacity: 0
    }
}

@keyframes show {
    to {
      opacity: 1
    }
}
@-webkit-keyframes show {
    to {
      opacity: 1
    }
}
