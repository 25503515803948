.breadcrumbs ol{
  width: 100%;
  background-color: var(--main-element);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.breadcrumbs ol::after{
  clear: both;
  content: "";
  display: table;
}
.breadcrumbs ol li{
  margin: 0;
  display: flex;
  align-items: center;
}

.breadcrumbs ol li{
  padding: 10px 0;
}
.breadcrumbs ol li::after{
  /* replace the default separator with a custom icon */
  display: inline-block;
  margin: 0 10px;
  content: '';
  height: 16px;
  width: 16px;
  background: url('./custom-separator.svg') no-repeat center center;
  vertical-align: middle;
  color: var(--main-placeholder);
}
.breadcrumbs ol li:last-of-type::after {
  display: none;
}
.breadcrumbs ol li > *{
  display: inline-block;
  font-size: var(--font-s);
  color: var(--main-text);
  cursor: pointer;
  white-space: nowrap;
}
.breadcrumbs ol li.current > *{
  color: var(--main-color);
}

@media only screen and (max-width: 480px) {

  .breadcrumbs ol{
    padding: 0 10px;
    overflow: auto
  }

  .breadcrumbs ol {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  
  }
  
  .breadcrumbs ol::-webkit-scrollbar {
    display: none;
  }  

  .breadcrumbs ol li::after{
    margin: 0 5px;
  }

}
