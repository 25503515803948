#order-refunds{
  position: relative;
}

#order-refunds .order-refunds{
  display: flex;
  flex-direction: column;
}

#order-refunds .order-refunds th{
  text-align: center;
  font-weight: bold
}

#order-refunds .order-refunds td{
  text-align: center;
}

#order-refunds .order-refunds .new-refund{
  display: flex;
  flex-direction: column;
}

#order-refunds .order-refunds .new-refund > *{
  width: 100%;
  margin-top: 10px;
}

#order-refunds .order-refunds .new-refund .labeled-textarea label textarea{
  width: 100%;
}
