#color-scheme-selector{
    display: flex;
    align-items: stretch;    
}

#color-scheme-selector .button{
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
}

#color-scheme-selector .button .fa{
    margin: 0;
}