.order-feedback-field .feedbacks{
  display: flex;
  justify-content: center;
}

.order-feedback-field .fa-frown{
  color: var(--invalid-color)
}

.order-feedback-field .fa-meh{
  color: var(--warning-color)
}

.order-feedback-field .fa-smile{
  color: var(--valid-color)
}

.order-feedback-field .separator{
  margin: 0 5px;
}
