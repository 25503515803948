.chartbox{
    margin: 20px 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 10px), 1fr));    
    gap: 10px

}

.chartbox .chart {
    background-color: var(--ui-element);
    padding: 10px;
    border-radius: 5px;
}

.chartbox .chart-title{
    padding: 10px;
    display: flex;
    align-items: center;
} 

.chartbox .chart-title .name{ 
    display: flex;
    flex-direction: column;
    
}

.chartbox .chart-title .total{
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 10px
}

.chartbox .chart-title .total > div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chartbox .chart-title .total .placeholder{
    color: var(--ui-placeholder);
    margin-bottom: 5px;
    font-size: var(--font-s);
}

.chartbox .chart-title .total .value{
    margin-right: 5px;
    font-size: var(--font-l);
}

.chartbox .chart-title h3{
    margin-bottom: 10px;
    font-size: 24px;
}

.chartbox .chart-title .note{
    color: var(--ui-placeholder)
}

@media only screen and (max-width: 480px) {

    .chartbox {
        grid-template-columns: repeat(auto-fit, minmax(calc(300px - 10px), 1fr));    
    }

  }
  
  