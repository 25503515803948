.checkbox input[type='checkbox']{
  height: 0;
  width: 0;
  display: none;
}

.checkbox .label{
  margin-left: 10px;
}

.checkbox input[type='checkbox'] + label{
  position: relative;
  display: flex;
  cursor: pointer;
  font-size: var(--font-s);
  align-items: center;
  color: var(--ui-placeholder);
  transition: color 250ms cubic-bezier(.4,.0,.23,1);
}

.checkbox input[type='checkbox'] + label > span{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 2px solid var(--ui-border);
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(.4,.0,.23,1);
}

.checkbox input[type='checkbox'] + label:hover,
.checkbox input[type='checkbox']:focus + label{
  color: var(--main-color);
}

.checkbox input[type='checkbox'] + label:hover > span,
.checkbox input[type='checkbox']:focus + label > span{
  background: rgba(255,255,255,.1);
}

.checkbox input[type='checkbox']:checked + label > span{
  animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
}

.checkbox input[type='checkbox']:checked + label > span:before{
  content: "";
  position: absolute;
  top: 8px;
  left: 5px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards;
}

@keyframes shrink-bounce{
  0%{
    transform: scale(1);
  }
  33%{
    transform: scale(.85);
  }
  100%{
    transform: scale(1);
  }
}
@keyframes checkbox-check{
  0%{
    width: 0;
    height: 0;
    border-color: var(--main-color);
    transform: translate3d(0,0,0) rotate(45deg);
  }
  33%{
    width: 4px;
    height: 0;
    transform: translate3d(0,0,0) rotate(45deg);
  }
  100%{
    width: 4px;
    height: 10px;
    border-color: var(--main-color);
    transform: translate3d(0,-.5em,0) rotate(45deg);
  }
}
