.loaderV2{
  display: flex;
  justify-content: center;
}

.loaderV2.full{
  height: 100%;
  display: flex;
  align-items: center;  
}

.loaderV2 .logo{
  animation: pulse 3s linear infinite;
  width: 100px;
  height: 100px;
}

@keyframes pulse {
  0% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
  }
  50% {
     -webkit-transform: scale(1);
     transform: scale(1);
  }
  100% {
      -webkit-transform: scale(0.8);
     transform: scale(0.8);
  }
}