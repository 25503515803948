.badge{
    padding: 5px;
    display: inline-flex;
    font-size: var(--font-xxs);
    align-items: center;
    background: var(--ui-bg);
    border-radius: 5px;
    gap: 5px;
    color: var(--ui-text);
    border: 1px solid var(--ui-border);
    background: var(--ui-element);
    cursor: pointer;
    user-select: none;
}

.badge .fa{
    align-items: center;
    display: flex;
    color: var(--ui-placeholder);
}

.badge .amount{
    display: flex;
}

.badge.main{
    color: var(--main-bg);
    border: 1px solid var(--main-color);
    background: var(--main-color);
}

.badge.main .fa{
    color: var(--main-bg);
}

.badge.valid{
    color: white;
    border: 1px solid var(--valid-color);
    background: var(--valid-color);
}

.badge.valid .fa{
    color: white;
}

.badge.warning{
    color: white;
    border: 1px solid var(--warning-color);
    background: var(--warning-color);
}

.badge.warning .amount,
.badge.warning .fa{
    color: white;
}

.badge.invalid{
    color: white;
    border: 1px solid var(--invalid-color);
    background: var(--invalid-color);
}

.badge.invalid .fa{
    color: white;
}
