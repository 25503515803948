.status-bar{
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.status-bar .status-bar-item{
  color: var(--ui-placeholder);
  align-items: center;
  padding: 15px;
  cursor: pointer;
  flex: 1;
  display: flex;
  min-width: 220px;
  border-radius: 5px;
  background: var(--ui-bg-helper);
  min-height: 35px;
}

.status-bar .status-bar-item .actions{
  margin-left: auto;
}

.status-bar .status-bar-item:last-child{
  border: 0;
}

.status-bar .status-bar-item .status-name{
  margin-right: 20px;
}

.status-bar .status-bar-item .time{
  align-items: center;
  margin-top: 5px;
  margin-left: auto;
  font-size: var(--font-xs);
  display: flex;
}

.status-bar .status-bar-item .time .fa{
  margin-right: 5px;
  color: var(--main-color);
  font-size: var(--font-xxs)
}

.status-bar .status-bar-item > .fa-check{
  display: none;
  margin-right: 15px;
}

.status-bar .status-bar-item.active .status-name{
  color: var(--main-color)
}

.status-bar .status-bar-item.visited > .fa-check{
  color: var(--valid-color);
  display: block;
}

.status-bar .status-bar-item:last-child{
  margin-bottom: 0;
}
