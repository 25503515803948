.product-price{
    display: flex;
    gap: 15px;
}

.product-price > *{
    flex: 1
}

.product-price > * .help{
    font-size: var(--font-xs);
    color: var(--ui-placeholder);
    margin-top: 10px;
    display: flex;
}
