.grids{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  gap: 15px;
}

.grids .grid{
  flex: 1;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background: var(--ui-bg);
}

.grids .grid.no-padding{
  padding: 0;
}

.grids .grid > .title{
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 20px;
}

.grids .grid.sidebar{
  flex: auto;
  max-width: 320px;  
}

@media only screen and (max-width: 1024px) {

  .grids .grid{
    flex-basis: 100%;
    flex: none !important;
  }

}