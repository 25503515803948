.vendor-stat{
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 3px 3px var(--ui-shadow);
    cursor: pointer;
}

.vendor-stat .title{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.vendor-stat .title .label{
    font-weight: bold;
    font-size: var(--font-m);
    margin-bottom: 10px;
}

.vendor-stat .title .date{
    margin-left: auto;
    font-size: var(--font-xs);
    color: var(--ui-placeholder)
}

.vendor-stat .stats{
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.vendor-stat .stats .stat *{
    user-select: none;
}


.vendor-stat .stats .stat .label{
    font-size: var(--font-xs);
    text-transform: uppercase;
    font-weight: bold;
    color: var(--ui-placeholder);
}

.vendor-stat .value{
    font-weight: bold;
    font-size: var(--font-large);
    margin: 5px 0 0;
}

.vendor-stat .value-change{
    font-size: var(--font-xs);
    display: flex;
    align-items: center;
    gap: 5px
}

.vendor-stat .value-change .positive{
    color: var(--valid-color)
}

.vendor-stat .value-change .neutral{
    color: var(--warning-color)
}

.vendor-stat .value-change .negative{
    color: var(--invalid-color)
}

@media only screen and (max-width: 900px) {

    .vendor-stat .stats{
        justify-content: flex-start;
    }

}

.pie-charts{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.pie-charts .chart{
    flex: 1;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 1px 3px var(--ui-shadow);
    padding: 20px;
}

.pie-charts .chart .chart-wrapper{
    max-width: 400px;
}

.pie-charts .chart .chart-wrapper{
    margin: 0 auto;
}

.pie-charts .chart .title{
    font-size: var(--font-m);
    text-align: center;
    margin: 0;
}

.stat-cards{
    display: flex;
    gap: 15px;
    align-items: stretch;
    overflow-x: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.stat-cards::-webkit-scrollbar {
    display: none;
}

.stat-cards .stat{
    box-shadow: 0 1px 3px var(--ui-shadow);
    padding: 15px;
    border-radius: 5px;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;    
}

.stat-cards .stat:last-child{
    margin-right: 40px;
}

.stat-cards .stat *{
    user-select: none;
}

.stat-cards .stat .label{
    font-size: var(--font-xs);
    text-transform: uppercase;
    font-weight: bold;
    color: var(--ui-placeholder);
}

.stat-cards .stat .value{
    font-weight: bold;
    font-size: var(--font-large);
    margin: 5px 0 0;
}

.stat-cards .stat .value-change{
    font-size: var(--font-xs);
    display: flex;
    align-items: center;
    gap: 5px
}

.stat-cards .stat .value-change .positive{
    color: var(--valid-color)
}

.stat-cards .stat .value-change .neutral{
    color: var(--warning-color)
}

.stat-cards .stat .value-change .negative{
    color: var(--invalid-color)
}

@media only screen and (max-width: 900px) {

    .stat-cards{
        justify-content: flex-start;
    }

}