#riders-filter{
  margin-bottom: 20px;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

#riders-filter > *{
  margin-bottom: 10px;
}

#riders-filter > *{
  height: auto;
}

#riders-filter.filter-bar-element .rider-button{
  height: auto;
}

#riders-filter .button > span{
  display: flex;
  align-items: center;
}

#riders-filter .rider-name{
  margin: 0 5px;
}

#riders-filter .filter-button button span{
  white-space: nowrap;
}

#riders-filter .fa-cog{
  margin-left: 5px;
  color: var(--ui-placeholder);
  font-size: var(--font-s)
}

#riders-filter .rider-button{
  padding: 8px 12px;
  font-size: var(--font-s);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ui-element);
  border: 0;
  color: var(--ui-text);
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  transition: all .2s ease-in-out;
}

#riders-filter .rider-button:hover{
  background: var(--ui-bg);
}

#riders-filter .rider-button > div{
  display: flex
}

#riders-filter .rider-button .actions-dropdown{
  margin-left: 5px;
}

#riders-filter .onbreak{
  font-size: var(--font-xs);
  color: var(--invalid-color)
}

#riders-filter .instrasfer{
  font-size: var(--font-xs);
  color: var(--warning-color)
}

#riders-filter .requested-icon{
  font-size: var(--font-s);
  color: var(--ui-placeholder);
  margin-right: 5px;
}

#riders-filter .requested-icon.finished{
  color: var(--valid-color)
}

#riders-filter .requested-icon.onbreak{
  color: var(--invalid-color)
}

#riders-filter .requested-icon.requested{
  color: var(--warning-color)
}
