/********** Filter bar **********/

.filter-bar{
  display: flex;
}

.filter-bar > *{
  margin-right: 10px;
}

.filter-bar > *:last-child{
  margin-right: 0;
}

.filter-bar .filter-button .active{
  background: var(--valid-color);
  color: var(--main-text)
}

.filter-bar .filter-button .inactive{
  background: var(--invalid-color);
  color: var(--main-text)
}

/********** Filter bar end **********/

/********** Page title **********/

.page-title{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

/********** Heading **********/

.heading{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.heading > div{
  width: 100%;
}

.heading .inner{
  display: flex;
  flex-direction: column;
}

.heading .title{
  font-size: var(--font-l);
  position: relative;
}

.heading .desc{
  font-size: var(--font-s);
  color: var(--ui-placeholder);
  margin: 20px 0 0;
  background-color: var(--ui-bg);
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.heading .desc .url input{
  background: none;
  border: 0;
  padding: 0
}

.heading .title::before{
  width: 60px;
  height: 4px;
  content: "";
  display: block;
  background: var(--main-color);
  position: absolute;
  bottom: -14px;
  left: 0
}

.heading .actions{
  margin-left: auto;
}

/********** Heading end **********/


/********** Table **********/


.table{
  width: 100%;
}

.table .link{
  cursor: pointer;
}

.table th{
  font-weight: bold;
  background: var(--ui-bg-helper)
}

.table td .field-filter{
  display: none;
  color: var(--main-color);
  font-size: var(--font-xs);
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
}

.table td:hover .field-filter{
  display: block;
}

.responsive-table .spacer,
.table .spacer{
  width: 100%;
}

.table .center{
  text-align: center;
  align-items: center;
}

.table .status{
  align-items: center;
  width: 15px;
  line-height: 1;
  text-align: center;
  font-size: var(--font-xxs);
}

.table .status .fa{
  color: var(--valid-color)
}

.table .disabled .status .fa{
  color: var(--invalid-color)
}

.table .actions > div{
  display: flex;
  justify-content: flex-end;
}

.table .nowrap,
.table .button{
  white-space: nowrap;
}

.table .actions .fa{
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.table .actions .button:hover .fa{
  color: var(--main-color)
}

.table{
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  overflow: hidden;
}

.table.full{
  width: 100%;
}

.table td,
.table th{
  padding: 10px 20px;
  background: var(--ui-bg);
  border-bottom: 1px solid var(--ui-border);
  text-align: left;
}

.table tr:last-child td{
  border-bottom: 0;
}

.table th{
  background: var(--ui-bg)
}


/********** Table end **********/




/********** Info table start **********/

.info-table{
  display: flex;
  flex-direction: column;
}

.info-table .info-table-item{
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid var(--ui-border);
}

.info-table .info-table-item .label{
    display: flex;
    align-items: center;
    color: var(--ui-placeholder);
    font-size: var(--font-s);
    line-height: 1;
}

.info-table .info-table-item .label .fa{
  color: var(--main-color);
  margin-right: 10px;
}

.info-table .info-table-item .info{
  margin-left: auto;
  text-align: right;
  flex: 1;
  margin-left: 50px;

}

@media only screen and (max-width: 414px) {

  .info-table .info-table-item{
    flex-direction: column;
  }

  .info-table .info-table-item .info{
    margin-left: 0;
    text-align: left;
    margin-top: 5px;
  }

}

/********** Info  table end **********/




/********** Tabs view start ***********/

.tabs-view{
  display: flex;
}

.tabs-view .menu{
  width: 260px;
  border-right: 1px solid var(--ui-border);
}

.tabs-view .tabs{
  border: 1px solid var(--ui-border);
  border-left: 0;
  flex: 1;
}

.tabs-view .menu .vertical-tabs-menu{
  width: 100%;
}


/********** Tabs view end ***********/
