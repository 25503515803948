.verified-upload{
    display: flex;
    align-items: center;
    background: var(--ui-element);
    border-radius: 5px;
    box-shadow: 0px 2px 2px 0px var(--ui-shadow);
    padding: 15px;
    gap: 15px;
}

.verified-upload .name{
    user-select: none;
}

.verified-upload > .icon{
    font-weight: bold;
}

.verified-upload .expiry{
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.verified-upload .info{
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.verified-upload .info .placeholder{
    font-size: var(--font-xxs);
    color: var(--ui-placeholder);
    margin-bottom: 5px;
}

.verified-upload .placeholder{
    font-size: var(--font-xxs);
    color: var(--ui-placeholder);
    margin-bottom: 5px;
}

#upload-document-modal{
    position: relative;
}
  
#upload-document-modal .drop-box {
    margin: 0 auto;
    position: relative;
    text-align: center;
    font-weight: bold;
    width: 100%;
    box-sizing: border-box;
    padding: 40px;
    align-items: center;
    color: var(--ui-placeholder);
    border: 2px dashed var(--ui-border);
    display: flex;
    transition: transform 0.3s;
    justify-content: center;
}

#upload-document-modal input[type="file"] {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
}