.partner-card{
  border: 0;
  margin: 5px 0;
  box-shadow: 0px 0px 5px 0px var(--ui-shadow);
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 5px;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  background: var(--main-bg)
}

.partner-card .vendor-logo{
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.partner-card .vendor-logo picture{
  width: 70px;
  height: 70px;
}

.partner-card .vendor-logo img{
  width: 100%;
  height: auto;
  bottom: 0;
  left: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 0 solid #fff;
}

.partner-card .logo-wrapper{
  margin-right: 10px
}

.partner-card .title h3{
  display: flex;
  align-items: center;
  min-height: 25px;
}

.partner-card .desc{
  color: var(--ui-placeholder);
  margin: 5px 0 10px;
  font-size: var(--font-xs);
  min-height: var(--font-xs);
}

.partner-card .info{
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.partner-card .bottom{
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px
}

.partner-card .inner{
  display: flex;
}

.icon-info{
  padding: 5px;
  margin: 0 3px 5px;
  display: flex;
  font-size: var(--font-xxs);
  align-items: center;
  background: var(--ui-bg);
  border-radius: 5px
}

.icon-info.main{
  color: var(--main-bg);
  border: 1px solid var(--main-color);
  background: var(--main-color);
}

.icon-info:first-child{
  margin-left: 0
}

.icon-info:last-child{
  margin-right: 0
}

.icon-info .fa{
  align-items: center;
  display: flex;
  color: var(--ui-placeholder);
  margin-right: 5px
}

.icon-info.main .fa{
  color: var(--main-bg);
}

.icon-info .amount{
  display: flex;
}
