.tag-editor{
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 5px;
}

.tag-editor .tags{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tag-editor .tags .item{
  background: var(--ui-element);
  border-radius: 5px;
  padding: 15px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  transition: all .2s ease-in-out;
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
}

.tag-editor .tags .item:hover{
  background-color: var(--ui-element-hvr);
}

.tag-editor .tags .item .fa{
  font-size: var(--font-xs);
}

.tag-editor .tags.active .item .fa{
  color: var(--ui-placeholder);
}

.tag-editor .add-custom{
  display: flex;
  gap: 10px;
}