.filter-bar{
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  align-items: stretch;
}

.filter-bar.wrap{
  flex-wrap: wrap;
}

.filter-bar > .left,
.filter-bar > .center,
.filter-bar > .right{
  display: flex;
}

.filter-bar > .center{
  align-items: center;
  justify-content: center;
  text-align: center;
}

.filter-bar > .right{
  justify-content: flex-end;
  margin-left: auto;
}

.filter-bar .select,
.filter-bar .button,
.filter-bar .labeled-input{
  height: 100%;
}

.filter-bar-element{
  display: flex;
  flex-wrap: nowrap;
}

.filter-bar-element.left{
  margin-left: auto;
}

.filter-bar.wrap .filter-bar-element.left{
  margin-left: 0;
}

.filter-bar-element > *{
  margin-right: 10px;
}

.filter-bar-element > .select{
  min-width: 150px;
}

.filter-bar-element .filter-button{
  white-space: nowrap;
}

.filter-bar-element .filter-button .active{
  background: var(--valid-color);
  color: var(--main-text)
}

.filter-bar-element .filter-button .inactive{
  background: var(--invalid-color);
  color: var(--main-text)
}
