.promotion-preview{
  font-size: var(--font-m);
  display: flex;
  background: #44b344;
  border-radius: 5px;
  color: white;
  padding: 15px;
  box-sizing: border-box;
  align-items: center;
}

.promotion-preview .title{
  font-size: var(--font-m);
}

.promotion-preview i{
  font-size: var(--font-giant);
}

.promotion-preview .info{
  margin-left: 5px;
}

.promotion-preview .info span{
  font-size: var(--font-s)
}
