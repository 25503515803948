#left{
  background: var(--main-bg);
  border-right: 1px solid var(--ui-border);
  width: 50px;
  transition: all 0.2s ease-in-out;
}

#left.opened{
  width: 250px;
}

#left .logo{
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  width: auto;
}

#left.open .logo{
  padding: 15px;
}

#left .logo img{
  max-height: 100%;
  height: auto;
  width: 100%;
}

#left .menu-header{
  border-bottom: 1px solid var(--ui-border);
  box-sizing: border-box;
  height: 60px;
}

#left .menu-toggle{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--main-placeholder)
}

#left .menu-toggle .fa{
  padding: 5px;
  margin-left: auto;
  background-color: var(--ui-element);
  border-radius: 5px;
}

#left .menu-toggle .fa{
  transition: all 0.4s ease;
  transform: rotateZ(-180deg);
}

#left.opened .menu-toggle .fa{
  transform: rotateZ(0);
}

@media only screen and (max-width: 600px) {

  #left{
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 999;
  }

  #app #main{
    margin-left: 50px;
  }

}