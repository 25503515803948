.chard-cards{
    display: flex;
    gap: 20px;
    overflow-x: auto;
}

.chard-cards::-webkit-scrollbar {
    display: none;
}

.chard-cards .card{
    padding: 20px;
    background-color: var(--ui-element);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.chard-cards .card .label{
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
}

.chard-cards .card .value{
    margin-top: auto;
    font-size: var(--font-xxl);
    text-align: center;
    color: var(--main-color)
}