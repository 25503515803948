.input{
    color: var(--ui-text);
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: var(--ui-element);
    min-height: 50px;
    border-radius: 5px;
    border: 0;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    font-size: var(--font-s)    
}

.input input{
    border: 0;
    background: transparent;
    width: 100%;
}