#preview{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

#preview > .title{
  color: var(--main-color);
  text-transform: uppercase;
}

#preview .partner-card{
  width: 360px;
}

@media only screen and (max-width: 480px) {

  #preview .partner-card{
    width: auto;
  }

}
