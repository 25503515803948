#add-product-addon .item{
  font-size: var(--font-s);
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--ui-border);
  padding-bottom: 10px;
}

#add-product-addon #add-new{
  display: flex;
  justify-content: center;
  margin: 10px 0;
  cursor: pointer;
  margin-top: 20px;
}

#add-product-addon #add-new .fa{
  margin-right: 5px;
}


#add-product-addon .selected .item{
  border-bottom: 0;
}

#add-product-addon .item .radio-dot{
  margin-right: 10px;
}

#add-product-addon .item .name{
  display: flex;
  flex-direction: column;
}

#add-product-addon .item .name .instruction{
  color: var(--ui-placeholder);
  margin-top: auto;
}

#add-product-addon .item .details{
  display: flex;
  margin-left: auto;
}

#add-product-addon .item .details .detail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

#add-product-addon .item .details .detail:last-child{
  margin: 0;
}

#add-product-addon .item .details .detail span:first-child{
  color: var(--ui-placeholder);
  font-size: var(--font-xs);
  line-height: var(--font-s)
}

#add-product-addon .item .details .detail span:last-child{
  color: var(--main-color);
  margin-top: auto;
}

#add-product-addon .button{
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-left: 40px;
}

#add-product-addon .required{
  background: var(--invalid-color);
  margin-left:20px;
  color: #fff;
  padding: 6px;
  font-size: var(--font-xxs);
  border-radius: 8px;
}
