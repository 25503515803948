.purchase-revisions .list{
    gap: 15px;
    display: flex;
    flex-direction: column;
}

.purchase-revisions .list > div{
    padding: 15px;
    box-sizing: border-box;
    background: var(--ui-element);
    border-radius: 5px;
    cursor: pointer;
}