#not-found-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

#not-found-wrapper.fill{
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 75vh
}

#not-found-wrapper span.fa{
  font-size: 80px;
  color: var(--ui-placeholder);
}
#not-found-wrapper.invalid span.fa{
  color: var(--invalid-color);
}

#not-found-wrapper p.error-text{
  color: var(--ui-text);
  font-size: var(--font-m);
  text-align: center;
}
