.labeled-input{
  position: relative;
}

.labeled-input * {
  height: 100%;
}

.labeled-input label{
  position: relative;
  display: block;
}

.labeled-input label input {
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid transparent;
  display: block;
  width: 100%;
  margin: 0;
  padding: 15px;
  background: var(--ui-element);
  color: var(--ui-text);
  font-size: var(--font-xs);
  line-height: var(--font-m);
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
  transition: all 0.2s ease-in-out;
  outline: none;
}

.labeled-input label input::-webkit-input-placeholder,
.labeled-input label input::-moz-placeholder,
.labeled-input label input:-ms-input-placeholder,
.labeled-input label input::placeholder{
  transition: all 0.2s ease-in-out;
  color: var(--ui-placeholder);
}

.labeled-input label input:focus,
.labeled-input.populated label input {
  padding-top: 28px;
  padding-bottom: 12px;
}

.labeled-input label input:focus::-webkit-input-placeholder,
.labeled-input.populated label input::-webkit-input-placeholder {
  color: transparent;
}

.labeled-input label input:focus::-moz-placeholder,
.labeled-input.populated label input::-moz-placeholder {
  color: transparent;
}

.labeled-input label input:focus:-ms-input-placeholder,
.labeled-input.populated label input:-ms-input-placeholder {
  color: transparent;
}

.labeled-input label input:focus::placeholder,
.labeled-input.populated label input::placeholder {
  color: transparent;
}

.labeled-input label input:focus + span,
.labeled-input.populated label input + span {
  opacity: 1;
  top: 10px;
  left: 15px;
  pointer-events: none;
}

.labeled-input label input + span {
  color: var(--main-text);
  font-size: var(--font-xxs);
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 20px;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
