#category .availability-time{
  display: flex
}

#category .availability-time > *{
  flex: 1;
  margin-right: 10px;
}

#category .availability-time > *:last-child{
  margin-right: 0;
}
