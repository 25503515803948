#setting-form > *{
  margin-bottom: 10px;
}

#setting-form .actions{
  display: flex;
}

#setting-form .actions > *{
  flex: 1;
  margin-right: 10px;
}

#setting-form .actions > *:last-child{
  margin-right: 0
}
