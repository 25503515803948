.cooking-time .current-time .fa{
  margin-left: 5px;
  font-size: var(--font-s)
}

.cooking-time .current-time > div{
  display: flex;
  flex-wrap: nowrap;
}

.cooking-time .ready-for-collect{
  display: flex;
  align-items: center;
  justify-content: center;
}

.cooking-time .ready-for-collect .label{
  display: flex;
  flex-direction: column;
}

.cooking-time .ready-for-collect .label .text{
  color: var(--ui-placeholder);
  font-size: var(--font-xxs);
  margin-bottom: 5px;
}

.cooking-time .ready-for-collect .preparation-time{
  text-align: center;
}
