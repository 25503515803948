.purchase-modifications{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.purchase-modifications .purchase-modification{
  padding: 10px;
  align-items: center;
  background-color: var(--ui-element);
  border-radius: 5px;
  display: flex;
  cursor: pointer;
}

.purchase-modifications .purchase-modification .info{
  display: flex;
  align-items: center;
  width: 100%;
}

.purchase-modifications .purchase-modification .status{
  font-size: 8px;
  margin-right: 10px;
}

.purchase-modifications .purchase-modification .info .price-wrapper{
  margin-left: auto;
}

.purchase-modifications .purchase-modification.recejted .status{
  color: var(--invalid-color)
}

.purchase-modifications .purchase-modification.pending .status{
  color: var(--warning-color)
}

.purchase-modifications .purchase-modification.approved .status{
  color: var(--valid-color)
}
