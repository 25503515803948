.active-purchases{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
}

.active-purchases .purchase{
    display: contents;
    align-items: center;
    justify-content: center;
}

.active-purchases .purchase > div{
    display: flex;
    align-items: center;
}