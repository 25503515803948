.filter .filter-box{
  border-bottom: 1px solid var(--ui-border);
  padding-bottom: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.filter .filter-box .saved-filters-box{
    display: flex;
    align-items: center;
    margin-top: 20px
}

.filter .filter-box .saved-filters{
  display: flex;
  margin-left: 20px;
  align-items: center;
}

.filter .filter-box .saved-filters > div {
  background: var(--ui-border);
  font-size: var(--font-xs);
  border-radius: 3px 0 0 3px;
  color: var(--main-text);
  display: inline-block;
  line-height: 26px;
  padding: 0 20px 0 10px;
  position: relative;
  margin-right: 10px;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  cursor: pointer;
}

.filter .filter-box .saved-filters > div .fa{
  font-size: var(--font-xxs);
  margin-right: 5px;
}

.filter .filter-box .saved-filters > div::after {
  background: var(--main-bg);
  border-bottom: 13px solid transparent;
  border-left: 10px solid var(--ui-border);
  border-top: 13px solid transparent;
  content: '';
  -webkit-transition: all 0.2s;
  position: absolute;
  right: 0;
  top: 0;
}

.filter .filter-box .saved-filters > div:hover {
  background-color: var(--main-color);
}

.filter .filter-box .saved-filters > div:hover::after {
   border-left-color: var(--main-color);
}
