.tabs{
  display: flex;
  background: var(--ui-bg);
  flex-direction: column;
}

.tabs .tab{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}

.tabs .tabs-menu{ 
  border-radius: 5px;
  overflow: hidden;
}

.tabs .tabs-menu ul{
  display: flex;
  background: var(--ui-element);
}

.tabs .tabs-menu ul li button{
  border-top: 4px solid transparent;
}

.tabs .tabs-menu.compact{
  margin-bottom: 20px;
}

.tabs .tabs-menu.compact ul{
  overflow: hidden;
  transition: all linear 200ms;
  height: 0;
  background: var(--ui-element);
  display: block;
  border-radius: 5px;
  border: 0;
}

.tabs .tabs-menu.compact ul li{
  border-bottom: 1px solid var(--ui-border)
}

.tabs .tabs-menu ul button{
  margin: 0 0 -1px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: var(--ui-placeholder);
  cursor: pointer;
}

.tabs .tabs-menu ul li.active button{
  color: var(--main-color);
  border-top: 4px solid var(--main-color);
}

.tabs .tabs-menu .toggle{
  padding: 20px;
  background: var(--ui-element);
  border-radius: 5px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tabs .tabs-menu .toggle .fa{
  margin-right: 10px;
}

.tabs .tabs-content{
  border-top: 0;
}


/***** DEPRECATED *********/

.vertical-tabs-menu{
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: var(--main-bg-helper);
}

.vertical-tabs-menu > div{
  margin: 0 0 0 1px;
  padding: 15px 25px;
  font-weight: 600;
  width: 100%;
  flex: 1;
  text-align: left;
  color: var(--ui-placeholder);
  cursor: pointer;
  border: 1px solid var(--ui-border);
  border-bottom: 0;
  border-right: 0;
  box-sizing: border-box;
}

.vertical-tabs-menu > div:last-child{
  border-bottom: 1px solid var(--ui-border);
}

.vertical-tabs-menu > div.active{
  color: var(--main-color);
  box-sizing: border-box;
  border-right: 1px solid var(--main-bg-helper);
  border-left: 2px solid var(--main-color);
}
