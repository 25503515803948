.map{
  height: 400px;
  overflow: hidden;
  position: relative;
}

.map .map-pin{
  left: 0;
  width: 20px;
  top: 0;
  height: 20px;
}

.map .map-pin span{
  font-size: 25px;
  color: var(--main-color);
}

.map .map-actions{
  position: absolute;
  display: flex;
  gap: 10px;
  top: 10px;
  right: 10px;
  z-index: 9000;
}
