.boxed{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: var(--ui-element);
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
  padding: 15px;
  gap: 15px;
  box-sizing: border-box;
}

.boxed .label{
  display: flex;
  flex-direction: column;
}

.boxed .label > .desc{
  color: var(--ui-placeholder);
  margin-top: 5px;
  font-size: var(--font-xs);
}

.boxed-button .value{
  margin-left: auto;
}

#boxed-text-modal textarea,
#boxed-text-modal input{
  border: 0;
  background-color: var(--ui-element);
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}