.vendor-tags{
  display: flex
}

.vendor-tags .vendor-tag{
  width: 22px;
  height: auto;
  margin-left: 5px;
}

.vendor-tags .vendor-tag .main{
  color: var(--main-color)
}

.vendor-tags .vendor-tag .green{
  color: var(--valid-color)
}

.vendor-tags .vendor-tag.slow .warning{
  color: var(--warning-color)
}
