#purchase-notes .purchase-notes{
  display: flex;
  flex-direction: column;
}

#purchase-notes .purchase-notes .purchase-note{
  padding: 10px;
  margin: 5px 0;
  border: 1px solid var(--ui-border);
  display: flex;
}

#purchase-notes .purchase-notes .purchase-note .owner{
  font-size: var(--font-xs);
  color: var(--ui-placeholder);
  margin-bottom: 5px;
}

#purchase-notes .labeled-textarea{
  margin-top: 5px;
}

#purchase-notes .actions{
  display: flex;
  margin-top: 10px;
  margin-left: auto;
}

#purchase-notes .actions > * {
  flex: 1;
  margin: 0 5px;
  cursor: pointer;
}

#purchase-notes .actions > *:first-child {
  margin-left: 0;
}

#purchase-notes .actions > *:last-child {
  margin-right: 0;
}
