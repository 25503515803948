.vendor-rating{
  display: flex;
  justify-content: center;
}

.vendor-rating.tooltip{
  line-height: 1.2
}

.vendor-rating.good .colored{
  color: var(--valid-color)
}

.vendor-rating.normal .colored{
  color: var(--warning-color)
}

.vendor-rating.bad .colored{
  color: var(--invalid-color)
}
