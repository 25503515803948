#customer-book-entries .price-field{
  text-align: right;
}

#customer-book-entries .date{
  white-space: nowrap;
}

#customer-book-entries .description-field .cell > div{
  display: flex;
  flex-direction: column;
}

#customer-book-entries .description-field .cell > div .message{
  color: var(--ui-placeholder);
  font-size: var(--font-xs);
  margin-top: 3px;
}

#customer-book-entries .item .tomato-point{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#customer-book-entries .item img{
  width: 20px;
  margin-left: 3px;
  height: auto;
}
