#purchase-totals{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

#purchase-totals .totals-table {
  margin-left: auto;
  text-align: left;
  flex: 1;
  width: 100%;
}

#purchase-totals .totals-table td{
  padding: 10px 0;
  border-bottom: 1px solid var(--ui-border)
}

#purchase-totals .totals-table td:last-child{
  text-align: right;
  width: auto;
}

#purchase-totals .order-notficiations{
  flex: 1
}

#purchase-totals .totals-info{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

#purchase-totals .totals-info .icon{
  color: var(--main-color);
  width: 30px;
  text-align: center;
  margin-right: 10px;
  font-size: var(--font-s)
}

#purchase-totals .totals-info .info{
  flex: 1;
  line-height: 1.2;
  cursor: pointer;
  font-size: var(--font-xs)
}

#purchase-totals .purchase-total.total{
  font-weight: bold;
}

#purchase-totals .purchase-total.total .price{
  font-size: var(--font-l);
  color: var(--main-color);
}  

@media only screen and (max-width: 414px) {

  #purchase-totals > *{
    flex-basis: 100%;
    flex: initial !important
  }

}