.rider-workshift-list{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.rider-workshift-list .scroller{
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;    
}

.rider-workshift-list .list-item{
    display: flex;
    align-items: center;
    gap: 10px
}

.rider-workshift-list .list-item .date{
    white-space: nowrap;
}

.rider-workshift-list .list-item > *{
    flex: 1
}

.rider-workshift-list .list-item > *:last-child{
    text-align: right
    ;
}

@media only screen and (max-width: 480px) {
    .rider-workshift-list .list-item .amount{
        margin-left: auto;
    }
}