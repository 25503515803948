.sidebar-view{
  display: flex;
  gap: 20px;
  max-width: 100%;
  flex-wrap: wrap;
}

.sidebar-view .sidebar-view-menu .sidebar-menu{
  border-radius: 5px;
  background: var(--ui-bg);
}

.sidebar-view .sidebar-view-content{
  max-width: 100%;
  background: var(--ui-bg);
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
}
