.connection-status{
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  border-radius: 5px;
  padding: 15px;
  color: var(--main-title);
}

.connection-status.connected{
  background-color: var(--valid-color);
}

.connection-status.disconnected{
  background-color: var(--invalid-color);
}

.connection-status .placeholder{
  display: none;
}

@media only screen and (max-width: 480px) {

  .connection-status .placeholder{
    display: none;
  }

}