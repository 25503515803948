#promotions-list{
  display: flex;
  flex-direction: column;
  width: 100%;
}

#promotions-list .promotion{
  display: flex;
  box-shadow: 0px 8px 8px 0px var(--ui-shadow);
  padding: 20px;
  border-radius: 5px;
  background: var(--ui-bg);
  align-items: center;
  margin-bottom: 10px;
}

#promotions-list .promotion .info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

#promotions-list .promotion .info .desc{
  color: var(--ui-placeholder);
  margin-top: 5px;
}

#promotions-list .promotion .context-menu{
  margin-left: auto;
}

#promotions-list .empty-list{
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#promotions-list .empty-list .empty-placeholder{
  margin-bottom: 20px;
}

#promotions-list .empty-list .fa{
  font-size: var(--font-xxl);
  color: var(--main-color);
  margin-right: 20px;
}

#promotions-list .empty-list .text{
  font-size: var(--font-l);
}
