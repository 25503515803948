.file-uploader{
  text-align: center;
  display: flex;
  background: var(--ui-element);
  border-radius: 5px;
  overflow: hidden;
}

.file-uploader .drop-box {
  margin: 5px;
  position: relative;
  text-align: center;
  font-weight: bold;
  width: 100%;
  padding: 35px;
  box-sizing: border-box;
  align-items: center;
  color: var(--ui-placeholder);
  border: 2px dashed var(--ui-border);
  display: flex;
  transition: transform 0.3s;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--ui-blurer)
}

.file-uploader .drop-box input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  border: 0;
  box-sizing: border-box;
}

.file-uploader .drop-box.draging {
  transform: scale(1.1);
}

.file-uploader .placeholder{
  position: absolute;
  top: -1px;
  left: 0;
  background: var(--ui-element-hvr);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
  display: flex;
}
