#help-dropdown{
  height: 100%;
  align-items: center;
  display: flex;
  cursor: pointer;
}

#help-dropdown .fas{
  margin-right: 5px;
}

#help-dropdown .dropdown-menu > ul{
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  display: none;
  width: 300px;
  right: 0;
  background: var(--ui-bg);
  border: 1px solid var(--ui-border)
}

#help-dropdown .dropdown-menu > ul > li{
  border-bottom: 1px solid var(--ui-border);
  cursor: pointer;
}

#help-dropdown .dropdown-menu > ul > li:last-child{
  border-bottom: 0;
}

#help-dropdown:hover .dropdown-menu > ul{
  display: block;
}


.copy-in-language .text{
  padding: 10px;
  cursor: pointer;
}

.copy-in-language .langs{
  display: flex;
}

.copy-in-language .langs > *{
  cursor: pointer;
  flex: 1;
  border-right: 1px solid var(--ui-border);
  text-align: center;
  padding: 10px 0;
}

.copy-in-language .langs > *:last-child{
  border-right: 0;
}

.copy-in-language .text:hover,
.copy-in-language .langs > *:hover{
  background: var(--ui-bg-helper)
}
