#review-product-addon .selected-addon{
  font-size: var(--font-s);
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#review-product-addon .selected-addon .required{
  background: var(--invalid-color);
  margin: 0 20px;
  margin-left: auto;
  color: #fff;
  padding: 6px;
  font-size: var(--font-xxs);
  border-radius: 5px;
}

#review-product-addon .selected-addon .name{
  display: flex;
  flex-direction: column;
}

#review-product-addon .selected-addon .name .instruction{
  color: var(--ui-placeholder);
  margin-top: auto;
}

#review-product-addon .selected-addon .details{
  display: flex;
}

#review-product-addon .selected-addon .details .detail{
  margin-right: 10px;
}

#review-product-addon .selected-addon .details .detail:last-child{
  margin: 0;
}

#review-product-addon .selected-addon .details .detail span:first-child{
  color: var(--ui-placeholder);
  font-size: var(--font-xs);
  line-height: var(--font-s);
  flex: 1;
}

#review-product-addon .selected-addon .details .detail .value{
  color: var(--main-color);
  margin-left: 5px;
  text-align: right;
}

#review-product-addon .selected-options{
  margin: 20px 0;
}

#review-product-addon .selected-options{
  margin: 5px 0;
}

#review-product-addon .selected-options > span{
  margin-bottom: 5px;
  display: block;
}

#review-product-addon .selected-options > ul{
  list-style: disc;
  padding-left: 20px;
}

#review-product-addon .selected-options .price{
  color: var(--main-color);
  margin-left: 5px;
}
