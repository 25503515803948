#addresses .address{
  display: flex;
  background: var(--ui-bg-helper);
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}

#addresses .address .info{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#addresses .address .actions{
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

#addresses .address .actions .button{
  width: 40px;
  height: 40px;
}

#addresses .address .building{
  color: var(--ui-placeholder);
  margin-top: 5px;
}
