#orders-list .actions{
  display: flex;
}

#orders-list .actions .button{
  position: relative;
}

#orders-list .actions > .button{
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#orders-list .actions .button .fa{
  margin-right: 0;
  text-align: center;
}

#orders-list .orders-list-item .assigned-rider{
  justify-content: center;
}

.customer-field .banned{
  color: var(--invalid-color);
  margin-left: 5px;
}

.customer-field .highvalue{
  color: var(--valid-color);
  margin-left: 5px;
}

.order-id-field .fa{
  color: var(--invalid-color);
  margin-right: 5px;
}

.order-id-field{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.order-id-field .checkbox{
  margin-right: 10px;
  display: none;
  transition: all .2s ease-in-out;
}

.problem .order-id-field .fa{
  color: var(--warning-color)
}

.selected .order-id-field  .checkbox,
.order-id-field:hover .checkbox{
  display: block;
}

/****************************/
/********** Fields **********/
/****************************/


/********** Cooking time field **********/

.cooking-time-field .cooking-time{
  display: flex;
  justify-content: center;
}

/********** Purchase status field **********/

th.purchase-status,
td.purchase-status-field{
  padding: 0;
  height: 0;
  position: relative;
  width: 10px;
}

th.purchase-status .cell,
td.purchase-status-field .cell{
  height: 100%;
}

.purchase-status-field .placeholder{
  width: 10px;
  height: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.purchase-status-field .placeholder .placeholder{
  height: 100%;
}

.purchase-status-field.inqueue .placeholder{
  background: white
}

.purchase-status-field.processed .placeholder{
  background: grey
}

.purchase-status-field.inpreparation .placeholder{
  background: #ffdc41
}

.purchase-status-field.readyforcollection .placeholder{
  background: #ff8b12
}

.purchase-status-field.ontheway .placeholder{
  background: #1984ff
}

.purchase-status-field.atlocation .placeholder{
  background: #32d13b
}

.purchase-status-field.completed .placeholder{
  background: #3c5922
}

.purchase-status-field.paymentfailed .placeholder,
.purchase-status-field.unprocessed .placeholder,
.purchase-status-field.rejected .placeholder,
.purchase-status-field.deliveryfailed .placeholder,
.purchase-status-field.cancelled .placeholder{
  background: var(--invalid-color)
}

@media screen and (max-width: 1200px) {

  td.purchase-status-field{

    padding: 0;
    position: relative;
    width: 100%;
  }

  td.purchase-status-field::before{
    display: none;
  }

  .purchase-status-field .placeholder{
    padding: 0;
    width: 100%;
    position: relative;
  }

}

/* VENDOR FIELD */

.vendor-field{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.vendor-field .actions-dropdown{
  margin-left: auto;
}

.vendor-field .link{
  margin-right: 10px;
  cursor: pointer;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  line-height: normal;
}

#balance-modal .table-scroller{
  overflow: scroll;
}

/* VENDOR FIELD END*/

/* CUSTOMER FIELD*/

.customer-field .link{
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  line-height: normal;
}

.customer-field{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}

.customer-field .tags{
  margin-left: 5px;
  display: flex;
}

.customer-field .tags .fas,
.customer-field .tags .fa{
  font-size: var(--font-xs);
  margin-right: 5px;
}

.customer-field .tags .fa-gift{
  color: var(--main-color)
}

.customer-field .tags .fa-exclamation-triangle{
  color: var(--invalid-color)
}

.customer-field .actions-dropdown{
  margin-left: auto;
}

.customer-field .plus{
  color: var(--valid-color)
}

.customer-field .minus{
  color: var(--invalid-color)
}

/* CUSTOMER FIELD END*/


.orders-list-item li.invalid{
  background: var(--invalid-color);
}

.orders-list-item li.invalid:hover{
  background: var(--invalid-hvr-color);
}

.orders-list-item li.warning{
  background: var(--warning-color);
}

.orders-list-item li.warning:hover{
  background: var(--warning-hvr-color);
}

/* RIDER FIELD*/

.rider-field .actions-dropdown{
  margin-left: auto;
}

.rider-field{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.rider-field .link{
  margin-right: 5px;
}

/* RIDER FIELD END*/

/* TAGS FIELD */

.order-tags-field .tags{
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-tags-field .tags .fa{
  font-size: var(--font-xs);
  margin: 0 5px;
  color: var(--main-color)
}

.order-tags-field .tags > div:first-child .fa{
  margin-left: 0
}

.order-tags-field .tags > div:last-child .fa{
  margin-right: 0;
}


/* RIDER FIELD END*/

/********************************/
/********** Fields end **********/
/********************************/


@media only screen and (max-width: 480px) {

  .vendor-field .link,
  .customer-field .link{
    max-width: 100px;
    line-height: auto;
  }

}
