*{
  font-family: 'Overpass', sans-serif;
}

.fa[class*='fa-'],
.fa[class*='fas-'],
.fa[class*='fab-']{
  display: inline-block;
  text-align: center;
  vertical-align: baseline;  
}

#main{
  overflow: hidden
}

body > tr.vendor {
  display: table !important;
}

.drag-handler{
  cursor: pointer;
}

.relative{
  position: relative;
}

input,
textarea{
  border: 1px solid var(--ui-border);
  outline: 0;
  padding: 10px;
  font-size: 14px;
  margin: 0;
  resize: none;
  color: var(--ui-text);
  background: var(--ui-element)
}

html{
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  font-family: 'Overpass', sans-serif;
  height: 100%;
}

body{
  margin: 0;
  height: 100%;
  padding: 0;
  background: var(--main-bg);
  color: var(--main-text);
}

body.modal-open{
  height: 100vh;
  overflow-y: hidden;
}

#root{
  height: 100%;
}

#app{
  display: flex;
  width: 100%;
  min-height: 100%;
}

#main{
  flex: 1;
}

.row.padding{
  padding: 40px;
  box-sizing: border-box;
}

.row.wrapped{
  width: 100%;
  display: block;
  margin: 25px auto;
  padding: 0 40px;
  max-width: 1440px;
  box-sizing: border-box;
}

.price{
  white-space: nowrap;
}

.ReactModal__Body--open {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.Toastify__toast{
  border-radius: 5px;
}

.ReactModal__Overlay{
  z-index: 8000;
  background-color: rgba(0, 0, 0, 0.4) !important
}

:disabled{
  cursor:not-allowed;
}

.fa-whatsapp{
  color: #25D366
}

.imessage{
  color: #147efb;
  cursor: pointer;
}

.content{
  display: flex;
}

.content .block{
  flex: 1;
  padding: 10px;
}

hr{
  border-color: var(--ui-border);
}

.relative{
  position: relative;
}

.portal-modal{
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  box-sizing: border-box;
  right: 0;
  justify-content: center;
  bottom: 0;;
  align-items: center;
  background: rgba(30,30,30,.7);
  display: inline-flex;
  padding: 20px;
  animation: fadein .2s;
}

.portal-modal .modal{
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.portal-modal .modal .modal-header{
  background: var(--ui-element);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  border-bottom: 1px solid var(--ui-border);
}

.portal-modal .modal .title{
  margin-bottom: 0;
  padding-bottom: 0;
}

.portal-modal .inner{
  border-radius: 5px;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: scroll;
  width: 100%;
  animation: scaleIn .2s;
  background: var(--ui-bg);
  padding: 20px;
  width: 100%;
  animation: scaleIn .2s;  
}

.portal-modal .filter-select{
  border: 0
}

.portal-modal .modal-footer{
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.portal-modal .modal-footer > button{
  flex: 1
}

.recharts-text{
  fill: var(--ui-text);
}

.recharts-text.recharts-label{
  fill: var(--ui-placeholder);
}

.Toastify__toast-theme--light{
  background: var(--ui-element);
  color: var(--ui-placeholder)
}

.fab.fa-whatsapp-square{
  color: var(--whatsapp)
}

@keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

@keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Firefox < 16 */
@-moz-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Internet Explorer */
@-ms-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Opera < 12.1 */
@-o-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}


@media only screen and (max-width: 480px) {

  .row.wrapped{
    padding: 0 20px;
  }

  .row.padding{
    padding: 20px;
  }

}

