.radio-dot {
  margin: 0;
}
.radio-dot input[type="radio"] {
  position: absolute;
  opacity: 0;
  outline: 0
}
.radio-dot input[type="radio"] + label{
    outline: 0;
    pointer-events: none;
    cursor: pointer;
}

.radio-dot input[type="radio"] + label:before {
  content: '';
  background: var(--ui-bg);
  border-radius: 100%;
  border: 2px solid var(--ui-border);
  display: inline-block;
  width: 25px;
  height: 25px;
  position: relative;
  top: 0;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
      cursor: pointer;
  transition: all 250ms ease;
}
.radio-dot input[type="radio"]:checked + label:before {
  background-color: var(--main-color);
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radio-dot input[type="radio"]:focus + label:before {
  outline: none;
}
.radio-dot input[type="radio"]:disabled + label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: var(--ui-border);
  background: var(--ui-border);
}
.radio-dot input[type="radio"] + label:empty:before {
  margin-right: 0;
}
