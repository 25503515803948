#product-details .price-box,
#product-details .purchase-qty{
  display: flex;
}

#product-details .price-box > *,
#product-details .purchase-qty > *{
  flex: 1;
  margin-left: 10px;
}

#product-details .price-box > *:first-child,
#product-details .purchase-qty > *:first-child{
  margin-left: 0
}

#product-details .actions{
  display: flex;
}

#product-details .actions .button{
  flex: 1
}

#product-details .actions .button{
  margin: 0 5px;
}

#product-details .actions .button:last-child{
  margin-right: 0;
}

#product-details .actions .button:first-child{
  margin-left: 0;
}
