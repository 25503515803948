.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown .menu-trigger {
  background: var(--main-element);
  border-radius: 5px;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  padding: 12px 18px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto; /* Strictly for positioning */
}

.dropdown .menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.dropdown .menu-trigger .fa {
  color: var(--main-color);
  font-weight: 600;
  font-size: var(--font-m);
  margin: 0 10px;
}

.dropdown .menu {
  background: var(--main-element);
  border-radius: 8px;
  z-index: 9000;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.dropdown .menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown .menu ul {
  list-style: none;
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,.4);
  padding: 0;
  margin: 0;
  border: 1px solid var(--ui-border);
  border-radius: 5px;
  overflow: hidden;
}

.dropdown .menu li {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--ui-border);
}

.dropdown .menu li:last-child{
  border-bottom: 0;
}

.dropdown .menu li .icon{
  margin-right: 5px;
  width: 20px;
  text-align: center;
  font-size: var(--font-s);
  color: var(--main-color)
}

.dropdown .menu li > * {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 15px 20px;
  border-bottom: 1px solid var(--ui-border);
}

.dropdown .menu li:last-child > * {
  border-bottom: 0;
}

.dropdown .menu li > *.active-link{
  color: var(--main-color)
}

@media only screen and (max-width: 480px) {

  .dropdown .menu-trigger{
    padding: 10px 14px
  }

}
