.assign-rider-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.assign-rider .rider-option{
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.assign-rider .rider-option .status-dot{
  margin-right: 5px;
}

.assigned-rider{
  display: flex;
  align-items: stretch;
  gap: 5px;
}

.assigned-rider > div{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.assigned-rider > div .icon{
  display: flex;
  flex-wrap: nowrap;
  gap: 5px
}

.assigned-rider .fa{
  font-size: var(--font-xs)
}

.assigned-rider .fa.fa-map-marker,
.assigned-rider .fa.fa-check{
  color: var(--valid-color);
}

.assigned-rider .fa.fa-times{
  color: var(--invalid-color);
}

.assign-rider-wrapper .assign-rider{
  display: flex;
  align-items: center;
}

.assign-rider-wrapper .assign-rider .actions .fa{
  color: var(--main-text);
  margin-left: 10px;
  font-size: var(--font-xs);
  cursor: pointer;
}

.assign-rider-wrapper .assign-rider .actions .fa:hover{
  color: var(--main-color);
}

#assign-rider-modal .active-riders{
  display: flex;
  flex-direction: column;
  gap: 10px
}

#assign-rider-modal .active-riders .rider{
  display: flex;
  flex: 1;
  width: 100%;
  background-color: var(--ui-element);
  padding: 15px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
}

#assign-rider-modal .active-riders .rider > div{
  display: flex;
  align-items: center;
  gap: 5px
}

#assign-rider-modal .active-riders .rider .tags{
  margin-left: auto;
}