.product-tags{
  display: flex;
}

.product-tag{
  margin: 0 5px;
  color: var(--main-color);
  cursor: pointer;
  font-size: var(--font-s)
}

.product-tag .vegetarian{
  color: var(--valid-color)
}

.product-tag .vegan{
  color: var(--valid-color);
  border: 1px solid var(--valid-color) ;
  border-radius: 50%;
  width: var(--font-s);
  height: var(--font-s);
  box-sizing: border-box;
  display: block;
  text-align: center;
}
