.save-overlay{
  position: relative;
}

.save-overlay .actions{
  position: absolute;
  display: none;
  top: 0;
  bottom: 0;
  right: 15px;
  align-items: center;
}

.save-overlay.edit .actions{
  display: flex;
  font-size: var(--font-xs);
}

.save-overlay .actions > *{
  cursor: pointer;
  margin-left: 10px;
}

.save-overlay .actions > *:first-child{
  margin-left: 0;
}

.save-overlay .actions > *:hover{
  color: var(--main-color)
}
