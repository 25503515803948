.labeled-select{
  position: relative;
  overflow: visible;
  height: 100%;
}

.labeled-select .labeled-input + ul{
  max-height: 0;
  overflow: hidden;
  position: absolute;
  background: var(--ui-bg);
  width: 100%;
  border: 0;
  border-top: 0;
  box-sizing: border-box;
  transition:all 200ms linear;
}

.labeled-select.opened .labeled-input + ul{
  max-height: 100px;
  margin-top: 5px;
  overflow-y: scroll;
  background-color: var(--ui-element);
  border: 0;
  border-radius: 5px;
  z-index: 100;
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,.4);
}

.labeled-select .labeled-input + ul li{
  padding: 10px;
  border: 0;
  cursor: pointer;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.labeled-select .labeled-input + ul li:hover{
  background: var(--ui-bg-helper)
}

.labeled-select .labeled-input + ul li:last-child{
  border-bottom: 0;
}

.labeled-select .labeled-input + ul .separator{
  height: 1px;
  width: 100%;
  padding: 0;
  border-top: 1px solid var(--ui-border)
}

.labeled-select .down{
  position: absolute;
  right: 0;
  margin-right: 10px;
  font-size: 12px;
  top: 50%;
  color: var(--main-color);
  transform: translateY(-50%);
  cursor: pointer;
}

.labeled-select .clear{
  position: absolute;
  right: 0;
  margin-right: 10px;
  font-size: 12px;
  top: 50%;
  color: var(--invalid-color);
  transform: translateY(-50%);
  cursor: pointer;
}
