#header{
  height: 60px;
  position: sticky;
  top: 0;
  background: var(--main-bg);
  z-index: 100;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--ui-border);
  box-sizing: border-box;
  padding: 0 40px;
}

#header .block{
  display: flex;
  align-items: center;
}

#header .block.left{
  margin-right: 20px;
}

#header .block.right{
  margin-left: auto;
  align-items: stretch;
  gap: 10px;
}

@media only screen and (max-width: 480px) {

  #header .user .menu-trigger span:first-child{
    display: none;
  }

  #header{
    padding: 0 20px;
  }

}
