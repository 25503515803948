#referral-code{
    margin-top: 15px;
    display: flex;
    gap: 15px
}


@media only screen and (max-width: 600px) {
    
    #referral-code{
        flex-direction: column;
        align-items: center;
    }

}