.thumbnail{
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 5px;    
    cursor: pointer;
}

.thumbnail > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.thumbnail .counter{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: var(--font-xxs);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 5px;
    box-sizing: border-box;
}

.yarl__slide{
    display: flex;
    flex-direction: column-reverse;
}

.yarl__slide_title_container{
    color: white;
    padding: 20px;
    position: absolute;
    top: 0;
    background: #0b0b0bd9;
    width: 100%;
    text-align: center;
}