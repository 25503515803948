#opening-hours{
  display: flex;
  flex-direction: column;
}

#opening-hours .day{
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 52px;
  margin-bottom: 10px;
  background: var(--ui-element);
  border-radius: 5px;
  padding: 10px;
}

#opening-hours .day-title{
  display: flex;
  align-items: center;
  gap: 15px;
}

#opening-hours .day .title{
  margin-right: 10px;
}

#opening-hours .day .hours-wrap{
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
}

@media only screen and (max-width: 480px) {

  #opening-hours .day{
    flex-direction: column;
  }

  #opening-hours .day .hours-wrap{
    margin: 20px 0;
  }

}
