#add-product-addon-option #add-new{
  display: flex;
  justify-content: center;
  margin: 10px 0;
  cursor: pointer;
  margin-top: 20px;
}

#add-product-addon-option #add-new .fa{
  margin-right: 5px;
}

#add-product-addon-option .option-list .price{
  margin-left: 20px;
  color: var(--main-color)
}
