#login-form .form .labeled-input{
  margin-bottom: 10px;
}

#login-form .form .actions{
  display: flex;
}

#login-form .form .actions .forget-password{
  margin-left: auto;
}
