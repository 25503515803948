.accordion{
    margin: 0 auto;
}

.accordion button.accordion-title{
    position: relative;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    font-weight: 700;
    padding: 20px 40px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border: 0 none;
    border-radius: 5px;
    outline: 0 none;
    cursor: pointer;
    z-index: 1;    
    color: var(--ui-text);
    background: var(--ui-element);
}

.accordion .accordion-title .fa{
    margin-left: auto;
    transition: .3s ease-in-out;
}

.accordion.opened .accordion-title .fa{
    transform: rotate(90deg);
}

.accordion .collapse{
    height: 0;
    background: var(--ui-bg);
    transition: height .3s ease-in-out;
    overflow: hidden;
    box-sizing: border-box;
}

.accordion .collapse > div{
    padding: 18px 36px;
}