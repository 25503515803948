.range-slider{
  display: flex;
  align-items: center;
  background: var(--ui-bg);
  border: 1px solid var(--ui-border);
  padding: 5px;
}

.range-slider input{
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  border-radius: 5%;
  border: 1px solid var(--ui-border);
  outline: none;
  padding: 0;
  margin: 0 10px;
}

.range-slider button{
  font-size: var(--font-xxs);
  background: transparent;
  border: 0;
  color: var(--ui-placeholder);
  outline: 0;
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.range-slider button:hover{
  color: var(--main-color)
}

.range-slider input::-webkit-slider-thumb{
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 0;
  background: var(--main-color);
  cursor: pointer;
}

.range-slider input::-moz-range-thumb{
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.range-slider .value{
  display: flex;
  align-items: center;
  font-size: var(--font-xs)
}
