#importer .import-file{
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

#importer .file-uploader{
  flex: 1;
  height: 100%;
  width: 100%;
}

#importer .select{
  margin-bottom: 10px;
}

#importer .button{
  margin-top: 10px;
  width: 100%;
}
