#cancel-purchase .cancel-time{
  display: flex;
  flex-direction: column;
  align-items: center;
}

#cancel-purchase .cancel-time .help{
  color: var(--ui-placeholder);
  text-align: center;
  display: flex;
  justify-content: center;
}

#cancel-purchase .cancel-time .timer{
  margin-bottom: 5px;
  color: var(--valid-color);
  font-size: var(--font-xxl);
  text-align: center;
}

#cancel-purchase .cancel-time .timer .expired{
  color: var(--invalid-color)
}

#cancel-purchase .cancel-actions{
  display: flex;
  flex-direction: column;
}

#cancel-purchase .cancel-actions .checkbox{
  margin-bottom: 10px;
}
