.multilang-input {
  position: relative;
  display: block;
  margin-bottom: 0;
}

.multilang-input  .languages{
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 6000;
}

.multilang-input  .languages > div{
  display: flex;
}

.multilang-input .languages button{
  font-size: 12px;
  background: var(--ui-bg-helper);
  padding: 4px 8px;
  box-sizing: border-box;
  border-radius: 0;
  outline: 0;
  cursor: pointer;
  border: 1px solid var(--ui-border);
  margin: 0;
  opacity: 0.6;
  display: flex;
  align-items: center;
}

.multilang-input .languages button *{
  pointer-events: none;
}


.multilang-input .languages button img{
  height: 15px;
  width: auto;
}

.multilang-input .languages button.active{
  opacity: 1;
  border-color: var(--main-color);
}
