.language-selector-tabs{
    display: flex;
}

.language-selector-tabs .button{
    background-color: var(--main-bg);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    opacity: 0.7;
}

.language-selector-tabs .button .value-wrapper > span{
    display: flex;
    gap: 10px;
    align-items: center;
}

.language-selector-tabs .button .error{
    color: var(--invalid-color);
}

.language-selector-tabs .button.active{
    opacity: 1;
    background-color: var(--ui-bg);
    border: 1px solid var(--ui-border);
    border-color: transparent;
}