#add-product-addon-modal .section:last-child{
  margin-bottom: 0
}

#add-product-addon-modal .section > .title h3{
  font-weight: 900;
  margin-bottom: 10px;
  font-size: var(--font-m)
}


#add-product-addon-modal .progress-steps{
  margin: 40px 60px;
}
