.purchase-product-refund{
    display: flex;      
    gap: 20px;
    justify-content: center;
    flex-direction: column;
}

.purchase-product-refund .row{
    display: flex;
    align-items: center;
    flex: 1;
}

.purchase-product-refund .row > span:first-child{
    color: var(--ui-placeholder);
}

.purchase-product-refund .row > div{
    margin-left: auto;
}
