.close-button{
  position: absolute;
  background: var(--ui-element);
  right: 10px;
  top: 10px;
  border: 0;
  color: var(--ui-placeholder);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  transition: all linear 200ms;
  z-index: 100;
}

.close-button:hover{
  background-color: var(--invalid-color);
  color: var(--main-title);
  cursor: pointer;
}
