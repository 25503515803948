.items-block{
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.items-block > *{
  width: 100%;
}

.items-block .items-block-item.fluid{
  flex: 1
}
