.info-text{
    display: flex;
    flex-direction: column;
}

.info-text span:first-child{
    color: var(--ui-text);
    font-size: var(--font-s);
}

.info-text span:last-child{
    font-size: var(--font-xs);
    color: var(--ui-placeholder);
}