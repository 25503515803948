.referral-stats{
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 40px 0 0 ;
}

.referral-stats .referral-stat{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.referral-stats .referral-stat .fa{
    font-size: 60px;
    color: var(--main-color);
}

.referral-stats .referral-stat h5{
    color: var(--ui-placeholder)
}

.referral-stats .referral-stat .value{
    font-size: var(--font-xl);
}

@media only screen and (max-width: 600px) {
    
    .referral-stats{
        flex-direction: column;
        gap: 40px;
    }

}