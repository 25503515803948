#modifications .filter-bar{
  display: flex;
  align-items: stretch;
}

#modifications .filter-bar .labeled-input{
  height: 100%
}

#modifications .filter-bar .labeled-date{
  margin-left: auto;
}
