.hour-picker{
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.hour-picker .fa{
  cursor: pointer;
}

.hour-picker > .placeholder{
  color: var(--main-color);
  font-size: 12px;
  position: absolute;
  top: 10px;
  left: 15px;
  cursor: pointer;
  pointer-events: none;
}

.hour-picker .data{
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
  margin: 0;
  padding: 25px 15px 10px;
  background: var(--ui-element);
  color: var(--ui-text);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.hour-picker.no-placeholder .data{
  padding: 15px;
}

.hour-picker .separator{
  font-size: var(--font-m);
  margin: 0 5px;
}

.hour-picker .hours,
.hour-picker .mins{
  color: var(--main-text);
  font-size: var(--font-m);
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hour-picker-modal .times{
  display: flex;
  width: 100%;
  gap: 15px;
}

#hour-picker-modal .time-wrap{
  background: var(--ui-element);
  border-radius: 5px;
  padding: 10px;
  flex: 1;
}

#hour-picker-modal .time-wrap .placeholder{
  font-size: var(--font-xxs);
  color: var(--ui-placeholder);
  pointer-events: none;
  cursor: pointer;
}

#hour-picker-modal .time{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#hour-picker-modal .time .fa{
  font-size: var(--font-s);
  cursor: pointer;
}

#hour-picker-modal .time .fa:first-child{
  color: var(--invalid-color)
}

#hour-picker-modal .time .fa:last-child{
  color: var(--valid-color)
}

#hour-picker-modal input{
  display: inline-flex;
  background: transparent;
  border: 0;
  color: var(--main-text);
  padding: 5px;
  outline: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-m)
}

#hour-picker-modal .actions{
  width: 100%;
  margin-top: 10px;
}
