#product-status{
  display: flex;
  flex-direction: column;
  align-items: center;
}

#product-status .actions{
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
}

#product-status .actions > *{
  margin-bottom: 15px;
}
